import React from 'react'
// import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import PropTypes from 'prop-types'
import PageLink from '../global/PageLink'
import RightArrowIcon from '../../graphics/icons/right-arrow-icon.svg'

import Container, { Section, Col } from '../global/Container'

import queryNews from '../../staticQueries/queryNews'

const NewsComponent = ({ className }) => {
  const entries = queryNews({limit: 2})
  return (
    <Section className={className} tw="bg-gray-light">
      <Container>
        <Col tw="flex justify-between">
          <PageLink to="/news">
            <h1>News</h1>
          </PageLink>
          <PageLink to="/news" tw="flex mt-1 text-sm md:text-base">
            Browse All
            <RightArrowIcon tw="ml-2" />
          </PageLink>
        </Col>
          {entries.map(entry => {
            return (
              <Col tw="w-full mb-4 sm:w-1/2" key={entry.id}>
                <PageLink to={entry.slug}>
                  <h3 tw="mb-1"><strong>{entry.title}</strong></h3>
                  <small>{entry.date}</small>
                  <p>{entry.description}</p>
                </PageLink>
              </Col>
            )
          })}
      </Container>
    </Section>
  )
}

NewsComponent.propTypes = {
  className: PropTypes.string.isRequired,
}

const News = styled(NewsComponent)`
  ${tw``}
`

export default News
