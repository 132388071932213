import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import PartsDropSearch from '../global/PartsDropSearch'
import { Translate } from 'gatsby-plugin-translate'

import { mQw, sizes } from '../../utils/mediaQueries'

import Container, { Col } from '../global/Container'

const HeroComponent = ({ image, background, className }) => {
  return (
    <div
      className={className}
      tw="overflow-hidden bg-cover relative h-80vh lg:max-h-full"
      style={{
        backgroundImage: `url(${
          background.childImageSharp
            ? background.childImageSharp.fluid.src
            : background
        })`,
      }}
    >
      <div tw="transform -translate-y-1/2 top-1/2 absolute z-10 w-full">
        <Container>
          <Col tw="flex justify-end">
            <div tw="w-full sm:w-2/3 lg:w-3/5 xl:w-1/2 text-white">
              <h1 tw="text-2xl leading-8 md:leading-10 md:text-3xl lg:text-4xl">
                <Translate>Techna NDT is the leading manufacturer of nondestructive testing tools and accessories for commercial aircrafts.</Translate>
              </h1>
              <PartsDropSearch tw="lg:w-3/4" />
            </div>
          </Col>
        </Container>
      </div>
      <img
        tw="transform absolute h-5/7 w-auto max-w-none md:mt-32 lg:mt-40 -right-1/6 mt-20 sm:mt-32 xs:right-0 sm:right-1/3"
        src={image.childImageSharp ? image.childImageSharp.fluid.src : image}
      />
    </div>
  )
}

HeroComponent.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  background: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string.isRequired,
}

const Hero = styled(HeroComponent)`
  min-height: ${sizes.xs}; 
  max-height: ${sizes.sm}; 
  @media (${mQw.lg}) {
    min-height: ${sizes.sm}; 
  }
  &::after {
    content: '';
    ${tw`bg-black xs:hidden opacity-25 inset-0 absolute`}
  }
  ${PartsDropSearch} {
    label {
      ${tw`bg-techna-blue bg-opacity-10`}
    }
    input {
      ${tw`w-full text-xl text-white pl-12`}
    }
    .search-label {
      ${tw`w-full text-base md:text-xl`}
      > svg {
        width: 32px;
        height: 32px;
      }
      > svg path {
        ${tw`fill-current`}
      }
    }
  }
`

export default Hero
