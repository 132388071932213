import { graphql, useStaticQuery } from 'gatsby'

const queryAirtableParts = () => {
  const query = useStaticQuery(
    graphql`
      query PartsQuery {
        query: allAirtable(filter: { table: { eq: "Parts" } }) {
          edges {
            node {
              id
              data {
                Part_Number
                Part_Description
                Part_Category {
                  data {
                    Category_Name
                    Category_Slug
                  }
                }
                Part_Variation {
                  data {
                    Variation
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const {
    query: { edges: partsData },
  } = query

  const parts = partsData.map(part => ({
    id: part.node.id,
    number: part.node.data.Part_Number,
    description: part.node.data.Part_Description,
    query: `${part.node.data.Part_Number} <small>${part.node.data.Part_Category[0].data.Category_Name}</small><p>${part.node.data.Part_Description}</p>`,
    category: part.node.data.Part_Category[0].data.Category_Name,
    categoryUrl: part.node.data.Part_Category[0].data.Category_Slug,
  }))

  return parts
}

export default queryAirtableParts
