import React from 'react'
// import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import PropTypes from 'prop-types'
import PageLink from '../global/PageLink'
import PartsDropSearch from '../global/PartsDropSearch'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CertificateBadge from '../../graphics/certificate-badge.svg'
import Container, { Section, Col } from '../global/Container'
import 'react-tabs/style/react-tabs.css';
import _ from 'underscore'

import HTML from '../../utils/HTML'

import RightArrowIcon from '../../graphics/icons/right-arrow-icon.svg'
import queryAirtableCategories from '../../staticQueries/queryAirtableCategories'

const PartsComponent = ({ className, data }) => {
  return (
    <Section className={className}>
      <Container>
        <Col tw="w-full sm:w-1/2 md:w-3/5">
          <PageLink to="/parts">
            <h1>{data.heading}</h1>
          </PageLink>
          <p>{data.body}</p>
        </Col>
        <Col tw="w-full sm:w-1/2 md:w-2/5">
          <div tw="rounded-lg border border-solid border-gray-400 px-4 pt-4 pb-1 sm:px-8 sm:pt-6 sm:pb-3 text-sm">
            <CertificateBadge tw="float-left mr-4 mb-4 mt-2" />
            <HTML content={data.certification} />
          </div>
        </Col>
        <Tabs tw="hidden lg:block w-full">
          <Col tw="w-full pt-20 xl:pt-12 relative">
            <TabList>
              {queryAirtableCategories().map(category => (
                <Tab key={category.id}>
                  {category.name}
                </Tab>
              ))}
              <PageLink to="/parts" tw="absolute top-0 right-0 mt-12 mr-6 xl:relative float-right flex xl:mt-3 xxl:mt-5 text-sm xxl:text-base">
                Browse All
                <RightArrowIcon tw="ml-2" />
              </PageLink>
            </TabList>
          </Col>
          {queryAirtableCategories().map(category => (
            <TabPanel key={category.id}>
              <div tw="w-full px-5 flex">
                {_.shuffle(category.types.map(type=> (
                  <div tw="w-1/4 px-3" key={type.id}>
                    <PageLink 
                      tw="block rounded-lg bg-gray-light p-8 text-sm h-full relative flex justify-center items-center"
                      to={`/parts/${category.slug}/${type.slug}`}>
                      <div tw="w-full pt-full relative mb-12">
                        <img src={type.image != null ? type.image.thumbnail : '/images/part-placeholder.jpg'} tw="object-contain absolute w-full h-full top-0 left-0" />
                      </div>
                      <span tw="absolute w-full bottom-0 left-0 pb-8 text-center">
                        {type.name}
                      </span>
                    </PageLink>
                  </div>
                ))).splice(0, 4)}
              </div>
            </TabPanel>
          ))}
        </Tabs>
        <Col>
          <div tw="md:border-solid md:mt-8 pt-8 lg:mt-0 lg:pt-0 lg:border-t-0 border-0 border-t border-gray-400 flex justify-center">
            <div>
              <PartsDropSearch />
              <p tw="text-gray-600 mt-2 text-sm">You can search by number or description.</p>
            </div>
          </div>
        </Col>
      </Container>
    </Section>
  )
}

PartsComponent.propTypes = {
  className: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
}

const Parts = styled(PartsComponent)`
  ${tw``}
  .react-tabs {
    ${tw`w-full`}
  }
  .react-tabs__tab {
    ${tw`pt-2 pb-3 px-4 text-sm xxl:text-base xxl:pt-4 xxl:pb-5 xxl:px-6 mb-0`}
  }
  .react-tabs__tab-list,
  .react-tabs__tab--selected {
    ${tw`border-gray-400`}
  }
  .react-tabs__tab--selected {
    ${tw`font-bold`}
  }
  .react-tabs__tab-panel {
    ${tw`hidden`}
  }

  .react-tabs__tab-panel--selected {
    ${tw`flex pt-6 pb-12`}
  }
`

export default Parts
