import React, { useMemo, useEffect, useRef, useState } from 'react'
import { List, AutoSizer } from 'react-virtualized'
import 'react-virtualized/styles.css'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import PropTypes from 'prop-types'
import matchSorter from 'match-sorter'

import queryAirtableParts from '../../staticQueries/queryAirtableParts'

import highlightSearch from '../../utils/highlightSearch'

import SearchIcon from '../../graphics/icons/search-icon.svg'

const SearchInputComponent = ({ className, isActive, searchValue, handleFocus, handleSearch }) => {

  return (
    <label className={`${className} ${(searchValue || isActive) ? 'active' : ''}`}>
      <input 
        type="text"
        value={searchValue}
        onFocus={handleFocus}
        onChange={handleSearch}
        tw="p-3 h-full w-full inline-block bg-transparent border-0 absolute inset-0 z-10"
      />
      <div className="search-label">
        <SearchIcon />
        <span>Search Parts Database</span>
      </div>
    </label>
  )
}

SearchInputComponent.propTypes = {
  className: PropTypes.string.isRequired,
  searchValue: PropTypes.string,
  handleSearch: PropTypes.func,
  handleFocus: PropTypes.func,
  isActive: PropTypes.bool,
}

const SearchInput = styled(SearchInputComponent)`
  ${tw`border rounded-md border-solid border-gray-400 block relative`}
  > input {
    ${tw`p-3 h-full w-full inline-block bg-transparent border-0 absolute inset-0 z-10`}
    /* opacity: 0; */
    padding-left: 38px;
  }
  > .search-label {
    ${tw`p-3 relative z-0 h-full flex items-center`}
    transition: all 0.3s ease-in-out;
    width: 228px;
    transform: translateX(-50%);
    left: 50%;
    > svg {
      margin-right: 4px;
    }
    > span {
      transition: opacity 0.3s ease-in-out;
      opacity: 1;
    }
  }
  &.active {
    > input {
      ${tw`outline-none`}
      opacity: 1;
    }
    > .search-label {
      left: 0;
      transform: translateX(0);
      > span {
        opacity: 0;
      }
    }
  }
`

const PartsDropSearchComponent = ({ className }) => {
  const parts = queryAirtableParts()
  const memoizedData = useMemo(() => parts)
  const searchFields = ['query']

  const [isActive, setIsActive] = useState(false)

  const ref = useRef();

  const handleFocus = () => {
    setIsActive(true);
  }

  useOnClickOutside(ref, () => {
    setIsActive(false);
  });

  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = event => {
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
  
          handler(event);
        };
  
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
  
        return () => {
          document.removeEventListener('mousedown', listener);
          document.removeEventListener('touchstart', listener);
        };
      },
      [ref, handler]
    );
  }

  const filterResults = inputValue => {
    const query = inputValue
    const filteredData = matchSorter(memoizedData, inputValue, {
      keys: searchFields,
    })
    const keys = searchFields
    return !inputValue && memoizedData
      ? memoizedData
      : highlightSearch(query, filteredData, keys)
  }

  const [filteredData, setFilteredData] = useState('')
  const [searchValue, setSearchValue] = useState('')

  const handleSearch = e => {
    const inputValue = e.target.value
    setSearchValue(inputValue)
    setFilteredData(filterResults(inputValue))
  }

  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }) => {
    const row = filteredData[index]
    const partSlug = row.number.replace(/(<([^>]+)>)/gi, '')
    return (
      <a key={key} style={style} href={`/parts/${row.categoryUrl}?s=${partSlug}`}>
        <div dangerouslySetInnerHTML={{ __html: row.query }} />
      </a>
    )
  }

  rowRenderer.propTypes = {
    key: PropTypes.string.isRequired,
    index: PropTypes.number,
    style: PropTypes.object.isRequired,
  }

  return (
    <div className={className} ref={ref}>
      <SearchInput searchValue={searchValue} isActive={isActive} handleFocus={handleFocus} handleSearch={handleSearch} />
      {searchValue && isActive &&
       <div className="parts-list-container">
          <div className="parts-list">
            <AutoSizer>
              {({ height, width }) => (
                <List
                  width={width}
                  height={height}
                  rowCount={filteredData.length}
                  rowHeight={48}
                  rowRenderer={rowRenderer}
                />
              )}
            </AutoSizer>
          </div>
        </div>
      }
    </div>
  )
}

PartsDropSearchComponent.propTypes = {
  className: PropTypes.string.isRequired,
  placeholder: PropTypes.object,
}

const PartsDropSearch = styled(PartsDropSearchComponent)`
  ${tw`relative`}
  .parts-list-container {
    ${tw`absolute bg-white rounded-b-md p-2 text-black box-border shadow-sm`};
    height: 200px;
    width: 100%;
  }
  .parts-list {
    height: 100%;
  }
  .parts-list a {
    ${tw`no-underline px-2 rounded-sm block`}
  }
  .parts-list a p {
    margin-top: -6px;
    font-size: 11px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .parts-list a small {
    ${tw`inline font-normal tracking-normal capitalize text-gray-600`}
  }
  .parts-list a:hover {
    ${tw`bg-gray-light`}
  }
  .parts-list span {
    ${tw`font-bold bg-techna-blue bg-opacity-20`}
    font-weight: 700;
  }
`

export default PartsDropSearch
