import React from 'react'
// import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import PropTypes from 'prop-types'

import Container, { Section, Col } from '../global/Container'
import HTML from '../../utils/HTML'

const StandardsComponent = ({ className, data }) => {
  return (
    <Section tw="bg-gray-dark" className={className}>
      <Container tw="text-white">
        <Col>
          <h1 tw="w-full block">{data.heading}</h1>
        </Col>
        <Col tw="md:w-2/5">
          <h2>{data.body}</h2>
        </Col>
        <Col tw="md:w-3/5"><HTML content={data.standardsList} /></Col>
      </Container>
    </Section>
  )
}

StandardsComponent.propTypes = {
  className: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
}

const Standards = styled(StandardsComponent)`
  ${tw``}
`

export default Standards
