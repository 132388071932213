import React from 'react'
// import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import PropTypes from 'prop-types'

import Container, { Section, Col } from '../global/Container'
import HTML from '../../utils/HTML'

const HistoryComponent = ({ className, data }) => {
  return (
    <Section className={className} tw="text-white bg-techna-blue">
      <Container>
        <Col>
          <h1>{data.heading}</h1>
        </Col>
        <Col tw="w-full xs:w-1/2 sm:w-3/5 xl:w-5/7">
          <HTML content={data.body} />
        </Col>
        {data.portrait &&
          <Col tw="w-full xs:w-1/2 sm:w-2/5 xl:w-2/7 mt-8 xs:mt-0">
            <figure>
              <img 
                src={ 
                  data.portrait.childImageSharp
                  ? data.portrait.childImageSharp.fluid.src
                  : data.portrait
                }
               />
               <figcaption>{data.portraitCaption}</figcaption>
            </figure>
          </Col>
        }
      </Container>
    </Section>
  )
}

HistoryComponent.propTypes = {
  className: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
}

const History = styled(HistoryComponent)`
  ${tw``}
`

export default History
