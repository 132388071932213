import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Hero from '../components/home/Hero'
import Standards from '../components/home/Standards'
import History from '../components/home/History'
import News from '../components/home/News'
import Parts from '../components/home/Parts'

export const HomeTemplate = ({ data }) => {
  return (
    <>
      <Hero image={data.heroimage} background={data.herobackground} />
      <Standards data={ data.standards } />
      <History data={ data.history } />
      <News />
      <Parts data={ data.partsPreview } />
    </>
  )
}

HomeTemplate.propTypes = {
  data: PropTypes.object
}

const Home = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <HomeTemplate
        data={ frontmatter }
      />
    </Layout>
  )
}

Home.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    }),
  }),
}

export default Home

export const pageQuery = graphql`
  query HomeTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "home" } }) {
      frontmatter {
        title
        herobackground {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        standards {
          heading
          body
          standardsList
        }
        history {
          heading
          body
          portrait {
            childImageSharp {
              fluid(maxWidth: 600, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          portraitCaption
        }
        partsPreview {
          heading
          body
          certification
        }
      }
    }
  }
`
